textarea,
input[type=text],
.select-option {
  font-size: 1.4rem;
  font-family: "BentonSansBBVA-Book", sans-serif;
  color: $color-secondary-500;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  option {
    font-weight: normal;
    padding: 0.2rem 0;
  }
}

.field-warning {
  color: $color-tertiary-red;
  font-size: 1.2rem;
  padding: 0.5rem 0;
}

input[type=checkbox] {
  height: 20px;
  width: 20px;
  color: $color-secondary-500;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.4em;
  background-color: #fff;
  accent-color: $color-primary-aqua;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    color: #222;
  }
}
