@font-face {
  font-family: "BentonSansBBVA-Book";
  src:
    url("../assets/fonts/BentonSansBBVA-Book.eot") format("eot"),
    url("../assets/fonts/BentonSansBBVA-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansBBVA-Light";
  src:
    url("../assets/fonts/BentonSansBBVA-Light.eot") format("eot"),
    url("../assets/fonts/BentonSansBBVA-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansBBVA-Medium";
  src:
    url("../assets/fonts/BentonSansBBVA-Medium.eot") format("eot"),
    url("../assets/fonts/BentonSansBBVA-Medium.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BentonSansBBVA-BookItalic";
  src:
    url("../assets/fonts/BentonSansBBVA-BookItalic.eot") format("eot"),
    url("../assets/fonts/BentonSansBBVA-BookItalic.woff2") format("woff");
  font-weight: normal;
  font-style: italic;
}

body, input, textarea {
  font-family: $font-primary-default;
  font-size: $default-font-size;
}

h1 { font-size: $h1-font-size; }
h2 { font-size: $h2-font-size; }
h3 { font-size: $h3-font-size; }

/* font-sizes */
.f-s-8 { font-size: 8px !important; }
.f-s-10 { font-size: 10px !important; }
.f-s-12 { font-size: 12px !important; }
.f-s-14 { font-size: 14px !important; }
.f-s-16 { font-size: 16px !important; }
.f-s-18 { font-size: 18px !important; }
.f-s-20 { font-size: 20px !important; }
.f-s-22 { font-size: 22px !important; }
.f-s-24 { font-size: 24px !important; }
.f-s-26 { font-size: 26px !important; }
.f-s-28 { font-size: 28px !important; }
.f-s-30 { font-size: 30px !important; }
.f-s-100 { font-size: 100px !important; }

.italic{
  font-family: "BentonSansBBVA-BookItalic", sans-serif ;
  font-style: italic;
}
