.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(25, 115, 184, 0.2);
  border-left: 1.1em solid $color-primary-medium-blue;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-loading {
  overflow: visible !important;
}

.modal-main {
  position: fixed;
  background: white;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 100%;
  overflow-y: scroll;

  &.no-padding {
    padding: 0;
  }

  .title {
    margin-bottom: 1em;
    font-size: 1.4em;
    text-align: left;

    i {
      margin-right: 10px;
    }

    i.icon-checkmark {
      color: $color-primary-aqua-dark;
    }

    i.icon-info {
      color: $color-primary-medium-blue;
    }

    i.icon-cross {
      color: $color-tertiary-red;
    }
  }

  .description {
    margin-bottom: 15px;
    font-size: 1em;
    text-align: left;
  }

  button {
    margin-top: 15px;
  }
}

.show-modal {
  display: block;
}

.hide-modal {
  display: none;
}