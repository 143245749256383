#root {
  position: relative;
  min-height: 100vh;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

.App-header {
  background-color: $color-primary-navy;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main-container {
    max-width: 1200px;
    margin: auto;
    width: 100%;
    font-size: calc(15px + 2vmin);
    color: $color-primary-white;
    padding: 0.4em;
    color: $color-primary-core-light-blue;
  }

  img {
    margin-right: 0.5em;
    height: calc(30px + 2vmin);
  }

  @media only screen and (max-width: $phone) {
    .main-container {
      font-size: calc(14px + 2vmin);
      padding: 0;
    }

    img {
      margin: 0 0.4em;
      height: calc(20px + 2vmin);
    }
  }
}

.main-container {
  padding: 30px 0 5vh;
  max-width: 1200px;
  margin: auto;

  @media only screen and (max-width: $phone) {
    padding: 10px;
  }
}

.app-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-primary-navy;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: $color-primary-white;
    font-size: 1.2rem;

    a {
      color: $color-primary-core-light-blue;
    }
  }

  @media only screen and (max-width: $phone) {
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
  }
}

.bullets {
  list-style: disc;
  margin-left: 3em;
}

.bg-grey {
  background-color: $color-secondary-100;
  padding: 2em;
  border-radius: 0.5rem;
}

.btns-container {
  display: flex;
  flex-direction: row;

  & a {
    margin-right: 50px;
  }
}

.container {
  display: flex;
  flex-direction: row;
}

.step-demographics,
.step-disc,
.step-priorities,
.step-ratings,
.step-final {
  .select-container {
    .select-item {
      width: 100%;
      margin-bottom: 0;

      label {
        display: inline-block;
        width: 33%;
        text-align: right;
        vertical-align: top;
        padding-top: 0.5rem;
      }

      .question,
      input,
      textarea,
      select {
        display: inline-block;
        max-width: 65%;
        width: 65%;
      }
    }
  }

  @media only screen and (max-width: $phone) {
    .select-container {
      flex-direction: column;

      .select-item {
        label {
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
        }

        .question,
        input,
        textarea,
        select {
          max-width: 100%;
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .model-participation {
    background-color: $color-secondary-200;
    border-radius: 0.5rem;
    padding: 1.5rem;
  }

  .captcha-center {
    display: inline-block;
  }
}

.step-final {
  .select-container {
    .select-item {
      label {
        text-align: left;
      }
    }
  }
}

.step-home {
  .home-text-container {
    min-height: 550px;

    @media only screen and (max-width: $phone) {
      min-height: auto;
    }

    .icon_grid {
      margin: 40px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        .img {
          width: 60px;
          margin-right: 10px;
        }

        i {
          font-weight: normal;
          margin-right: 10px;
          font-size: 2em;
        }
      }

      @media only screen and (max-width: $phone) {
        margin: 25px 10px;
        grid-gap: 20px;
        grid-template-columns: 1fr;

        div {
          flex-direction: column;

          .img {
            width: 50px;
            margin: 0 0 10px 0;
          }

          i {
            margin: 0 0 10px 0;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }

  .home-img {
    position: absolute;
    bottom: 0;
  }

  .disclaimer-GDPR {
    font-size: 1rem;
    text-align: justify;
    position: absolute;
    bottom: 0;

    @media only screen and (max-width: $phone) {
      position: relative !important;
    }
  }
}

.step-influence {
  .influence-box {
    width: 30%;
  }

  .flexrow-container-influence {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .motivators-list {
    min-height: 300px;
    width: 100%;
    display: inline-block;

    .item {
      min-height: 50px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }

  @media only screen and (max-width: $phone) {
    .influence-box {
      width: 100%;
    }

    .flexrow-container-influence {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .motivators-list {
      min-height: 60px;
      width: 100%;
    }
  }
}

.success {
  margin: 0 auto;
  max-width: 1000px;

  .results-box {
    display: block;
    width: 100%;
    background: $color-secondary-100;
    text-align: center;

    @media only screen and (max-width: $phone) {
      text-align: center;
    }

    .chart {
      background: white;
      display: inline-block;
      vertical-align: top;
    }

    .left-container {
      display: inline-block;
      min-width: 600px;
    }

    .description {
      display: inline-block;
      vertical-align: top;
      padding: 20px;
      font-size: 1.25rem;

      p {
        margin-bottom: 15px;
      }
    }

    .tip-list {
      list-style-type: disc;
      text-align: justify;
    }

    &--transparent {
      background: transparent;
    }
  }

  .btn-img {
    width: 50px;
  }
}

.rating-titles {
  margin: 0 auto;
  width: 100%;
  clear: both;
  min-height: 30px;

  & p {
    display: inline-block;
    width: 80px;
    font-size: 0.8em;
    float: left;
  }
}

.rating-titles-p {
  margin: 0 auto;
  width: 100%;
  clear: both;
  min-height: 30px;

  & p {
    display: inline-block;
    width: 55px;
    font-size: 0.8em;
    float: left;
  }
}

.motivators-list {
  display: inline-block;
  vertical-align: top;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 1em;
  background: $color-secondary-100;
  min-height: 15px;
  margin: 10px auto;

  .item {
    display: inline-block;
    margin: 5px;
    padding: 15px;
    background: $color-primary-core-blue;
    color: white;
    cursor: move;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.ghost {
      opacity: 0;
    }
  }

  .card {
    opacity: 0;
    width: 100px;
    height: 50px;
  }
}

.motivators-sorted {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  height: auto;
  padding: 0.5em 0.5em;
  min-height: 90px;
  margin: 10px auto;
  background: transparent;
  border: 1px solid $color-secondary-200;
  min-height: 150px;

  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  justify-content: flex-start;

  &::after {
    content: "Mueve a esta zona los elementos para ordenarlos";
    position: absolute;
    top: 50%;
    left: 50%;
    color: $color-primary-navy; /*$color-secondary-400;*/
    border: 2px dashed $color-secondary-300;
    padding: 2em 1em;
    font-size: 16px;
    z-index: 0;
    transform: translate(-50%, -50%);
  }

  .card {
    display: inline-block;
    width: 32.9%;
    margin: 0.2% 0.2%;
    background-color: $color-primary-core-blue;
    cursor: move;
    z-index: 1;

    .card-title {
      background-color: $color-primary-navy;
      padding: 0.5em 1em;
      color: white;
    }

    .card-content {
      padding: 1em;
      color: white;
    }

    &.ghost {
      color: transparent;
      background: transparent;

      border: 2px dashed $color-secondary-300;
      & > * {
        display: none;
      }
    }
  }

  .item {
    width: 32.9%;
    color: transparent;
    margin: 0.2% 0.2%;
    border: 2px dashed $color-secondary-300;
    min-height: 100px;
  }

  @media only screen and (max-width: $desktop-s) {
    margin: 10px 0.5em;
    width: auto;

    .card {
      display: block;
      width: 100%;
      margin: 1%;
    }
  }
}

.motivators-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;

  .card {
    display: inline-block;
    width: 31%;
    margin: 1vw 1%;

    .card-title {
      background-color: $color-primary-navy;
      padding: 0.5em 1em;
      color: white;
    }

    .card-content {
      background-color: $color-secondary-100;
      padding: 1em;
      align-items: stretch;

      .rating {
        display: inline-block;
        width: 300px;
        margin-bottom: 15px;

        .stars-tags {
          display: flex;
          width: 290px;
          justify-content: space-between;
          font-size: 10px;
        }

        .star-container {
          padding-right: 5px !important;
          padding-left: 0px !important;
        }
      }

      textarea {
        background-color: white;
        font-size: 1em;
        padding: 0.5em;
      }
    }
  }

  @media only screen and (max-width: $desktop-s) {
    .card {
      display: block;
      width: 100%;
    }
  }
}

.finish-button {
  text-align: left;
  padding-left: 5em;
  padding-right: 5em;
  float: right;

  @media only screen and (max-width: $phone) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.multiple-selection-tip {
  font-size: 12px;
  font-style: italic;
}

.error-page {
  color: $color-primary-navy;
  margin: 20px;
}

.thanks {
  color: $color-primary-navy;

  .img {
    width: 80px;
  }
}

.flexcolumn-container {
  display: flex;
  flex-direction: column;
}

.flexrow-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.enps-container {
  counter-reset: valoracion_enps;

    .enps-icon {
      font-size: 1.8em;
      margin: 0 0.4em;
    
      /*
      &:nth-of-type(odd) {
        counter-increment: valoracion_enps 1;
      }

      &::after {
        font-family: $font-primary-bold;
        font-weight: normal;
        font-size: 0.4em;
        content: counter(valoracion_enps);
      }
      */

      &--low {
        color: $color-tertiary-red;
      }
      
      &--medium {
        color: $color-secondary-500;
      }

      &--high {
        color: $color-primary-aqua;
      }
    }

    @media (max-width: $phone) {
      .enps-icon {
        font-size: 1.2em;
        margin: 0 0.2em;
      }
    }
}

