.tooltip {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    padding: 10px 10px;
    background: $color-secondary-100;
    border: 1px solid $color-secondary-300;
    font-size: 12px;
    box-shadow: 0px 10px 30px -20px rgba(0,0,0,0.75);

    .title {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        font-weight: bolder;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .description {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .rating {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin: 15px 0 0;
        padding: 0;
        span { 
            display: inline; 
            margin-right: 7px; 
            color: $color-primary-medium-blue;
            font-size: 20px;
        }
    }
}