.buttons-group {
  align-items: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  @include respond(tab-port) {
    flex-direction: row-reverse;
  }

  @include respond(desktop-s) {
    justify-content: flex-end;
  }

  .btn {
    width: 100%;

    @include respond(desktop-s) {
      width: auto;
    }
    &:focus {
      outline: none;
    }
    &::-moz-focus-inner {
      border: 0;
    }

    &:not(:last-child) {
      margin-bottom: 10px;

      @include respond(tab-port) {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}

.btn {
  border: 0 none;
  cursor: pointer;
  display: inline-block;
  font-family: $font-primary-default;
  font-size: 1em;
  outline: 0 none;
  padding: 15px 20px;
  position: relative;
  text-decoration: none;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  &[disabled] {
    background-color: $color-secondary-300 !important;
    cursor: not-allowed;
  }
}

.btn-primary {
  background-color: $color-primary-medium-blue;
  color: $color-primary-white;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary-core-light-blue;
  }

  &.checked {
    background-color: $color-primary-medium-light-blue;
  }
}

.btn-secondary {
  background-color: $color-primary-aqua;
  color: $color-primary-white;

  &:hover,
  &:focus {
    background-color: $color-primary-aqua-medium;
  }
}

.btn-margin {
  margin-left: 15px;
}

.btn-danger {
  background-color: $color-tertiary-red;
  color: $color-primary-white;

  &:hover,
  &:focus {
    background-color: $color-tertiary-red-dark;
  }
}

.btn-warning {
  background-color: $color-tertiary-orange;
  color: $color-primary-white;

  &:hover,
  &:focus {
    background-color: $color-tertiary-orange-dark;
  }
}

.btn-success {
  background-color: $color-tertiary-green;
  color: $color-primary-white;

  &:hover,
  &:focus {
    background-color: $color-tertiary-green-dark;
  }
}

.btn-ghost {
  background-color: transparent;
  color: $color-primary-medium-blue;

  &[disabled] {
    color: $color-secondary-400;
    &:hover,
    &:focus {
      color: $color-secondary-400;
    }
  }

  &:hover,
  &:focus {
    color: $color-primary-core-blue;
  }
}

