@mixin absCenter {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absCenterReset {
  left: auto;
  position: relative;
  top: auto;
  transform: none;
}

// MEDIA QUERY MANAGER
/*
$breakpoint argument choices
- phone
- tab-port
- desktop-s
- desktop-m
- desktop-l
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: $phone) { @content };
  }
  @if $breakpoint == tab-port {
    @media only screen and (min-width: $tab-port) { @content };
  }
  @if $breakpoint == desktop-s {
    @media only screen and (min-width: $desktop-s) { @content };
  }
  @if $breakpoint == desktop-s-to-m {
    @media only screen and (min-width: $desktop-s) and (max-width: calc(#{$desktop-m} - 1px)) { @content };
  }
  @if $breakpoint == desktop-m {
    @media only screen and (min-width: $desktop-m) { @content };
  }
  @if $breakpoint == desktop-l {
    @media only screen and (min-width: $desktop-l) { @content };
  }
}