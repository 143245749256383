// -----------------------------------------------------------------------
// LAYOUT
// -----------------------------------------------------------------------

// TABLE OF CONTENTS:
// - 00 + CONTAINER FLUID
// - 01 + CONTAINER
// - 02 + ROW /COLUM / COL
// - 03 + CONTENT
// - 04 + SIDEBAR
// - 05 + AUX CLASSES


// - 00 + CONTAINER FLUID

.container-fluid {
  position: relative;
  clear: both;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  .column { padding: 0px;}
}

// - 01 + CONTAINER
.container {
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1170px;

  &::after {
      content: " ";
      display: block;
      clear: both;
  }

  @media (min-width: 768px) {
   /* max-width: 1176px;*/
    margin-left: auto;
    margin-right: auto;

    &::after {
      content: " ";
      display: block;
      clear: both;
    }
  }

  @media (min-width: 1024px) {
   /* max-width: 1176px;*/
    margin-left: auto;
    margin-right: auto;
    
    &::after {
      content: " ";
      display: block;
      clear: both;
    }
  }
}

section.container {
    margin-bottom: 30px;
    margin-top: 30px;
}

// - 01 + ROW / COLUM / COL
.row {
  clear: both;
  margin-left:  -15px;
  margin-right: -15px;
}
.column { padding: 0px 16px; }

.col-span-4 .column, 
.col-span-3 .column, 
.col-span-2 .column, 
.col-span-1 .column, 
/* TO DO revisar cuando mezclemos custom-header new */
header .main-navigation .logo-container, 
header .main-navigation .link-container {
    box-sizing: border-box;
    position: relative;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
}

// - 03 + CONTENT
/* Tells Susy there's 8 columns in the content */
.content {
  width: 100%;
  float: left;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 768px) {
    width: 50%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 1024px) {
    width: 66.66667%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
}

// - 04 + SIDEBAR
.sidebar {
  width: 100%;
  float: left;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 768px) {
    width: 50%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 1024px) {
    width: 33.33333%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
}

// - 05 + AUX CLASSES
.clearfix, .container, .container-fluid, .row {
  &::before, &::after {
    content: " ";
    display: table;
  }
  &::after { clear: both;}
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right { float: right !important;}
.pull-left  { float: left !important;}
.hide       { display: none !important;}
.show       { display: block !important;}
.invisible  { visibility: hidden;}
.hidden     { display: none !important;}
.affix      { position: fixed;}

.text-hide  {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@-ms-viewport { width: device-width;}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
  .visible-sm-block {
    display: block !important;
  } 
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
  .visible-lg-block {
    display: block !important;
  }
  .visible-lg-inline {
    display: inline !important;
  }
  .visible-lg-inline-block {
    display: inline-block !important;
  }
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

/* workaround for safari */
.row-eq-height:before,
.row-eq-height:after {
  content: normal;
}




